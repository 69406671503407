import React from 'react';
import { Typography } from '@mui/material';
import { Business, AccountBalance, MonetizationOn, SettingsApplications } from '@mui/icons-material';

function SobreMobile() {
  return (
    <div>
      <Typography variant="h4" align="center">Sobre nós</Typography>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <div style={{ marginBottom: '20px' }}>
          <SettingsApplications fontSize="large" />
          <Typography variant="subtitle1">Missão</Typography>
          <Typography>
            A missão da VELB Studios é ajudar comerciantes a registrar com precisão suas atividades,<br /> facilitando o gerenciamento e a tomada de decisões estratégicas.
          </Typography>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <AccountBalance fontSize="large" />
          <Typography variant="subtitle1">Visão</Typography>
          <Typography>
            Buscamos evoluir as soluções digitais diariamente,<br /> oferecendo ferramentas que economizam tempo e aumentam a produção <br /> para impulsionar o sucesso dos nossos clientes.
          </Typography>
        </div>
        <div>
          <MonetizationOn fontSize="large" />
          <Typography variant="subtitle1">Valores</Typography>
          <Typography>
            Nossos valores incluem eficiência, inovação e compromisso <br /> com o crescimento dos negócios dos nossos clientes.
          </Typography>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Typography variant="subtitle2">VELB SISTEMAS @coporation 2023 versão 1.0.1</Typography>
        <Typography variant="subtitle2">Termos de Uso • Política de Privacidade</Typography>
        <Typography variant="subtitle2">© 2023 VELB • SISTEMAS PARA SOLUÇÕES DIGITAIS LTDA • CNPJ: 27.864.392/0001-93</Typography>
      </div>
    </div>
  );
}

export default SobreMobile;

