import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { app, database } from './firebaseConfig';
import { ref, push, onValue ,set} from 'firebase/database';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { useNavigate } from 'react-router-dom';
import SavedItems from './SavedItems';
import MobileSavedItems from './MobileSavedItems';
import './Home.css';
import './Modal.css'; // Importe o arquivo CSS aqui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';


import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



// Função para obter o caminho de armazenamento no Firebase
const getStoragePath = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const monthName = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(currentDate);
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}/${monthName}/${day}`;
};


function Home({ isDarkMode, toggleDarkMode,isSidebarVisible }) {
const [message, setMessage] = useState('');
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay());
const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [nome, setNome] = useState('');
  const [valor, setValor] = useState('0,00');
  const [data, setData] = useState('');
  const [categoria, setCategoria] = useState('');
  const [showCarousel, setShowCarousel] = useState(false);
  const [categoriaClicada, setCategoriaClicada] = useState(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState({ nome: '', tipo: '' });
  const [categorias, setCategorias] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
const [selectedDate, setSelectedDate] = useState(new Date());
  const categoryListRef = useRef(null);

  const currentDate = new Date();
  const currentDateTime = currentDate.toLocaleString();
const [categoriaSelecionadaIndex, setCategoriaSelecionadaIndex] = useState(0);
const [snackbarOpen, setSnackbarOpen] = useState(false);

 function getTimeOfDay() {
    const currentHour = new Date().getHours();

    if (currentHour >= 6 && currentHour < 12) {
      return 'manha';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'tarde';
    } else {
      return 'noite';
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeOfDay(getTimeOfDay());
    }, 60000); // Atualiza a cada minuto

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  useEffect(() => {
    if (timeOfDay === 'manha') {
      setMessage("Bom dia,Que o seu dia seja cheio de realizações e alegrias!");
    } else if (timeOfDay === 'tarde') {
      setMessage("Boa tarde,Espero que sua tarde seja produtiva e inspiradora!");
    } else {
      setMessage("Boa noite,Tenha uma noite tranquila e revigorante!");
    }
  }, [timeOfDay]);
 
 const handleF7Click = () => {
    setSnackbarOpen(true);
  };

  const handleF8Click = () => {
    setSnackbarOpen(true);
  };




const handleSaveWithF6 = (event) => {
  if (event.keyCode === 117 && openModal) { // Verifica se a tecla é F6 e se o modal está aberto
    event.preventDefault();

    // Restante do código para salvar os dados e executar a lógica de adição
    localStorage.setItem('nome', nome);
    localStorage.setItem('valor', valor);
    localStorage.setItem('data', data);
    localStorage.setItem('categoria', categoria);
    localStorage.setItem('categoriaSelecionada', JSON.stringify(categoriaSelecionada));

    handleSalvarClick();
  }
};


 useEffect(() => {
  if (openModal) {
    document.addEventListener('keydown', handleSaveWithF6);
  } else {
    document.removeEventListener('keydown', handleSaveWithF6);
  }

  return () => {
    document.removeEventListener('keydown', handleSaveWithF6);
  };
}, [openModal, nome, valor, data, categoria, categoriaSelecionada]);




const categoriaInputRef = useRef(null);
 const nameInputRef = useRef(null);
  const valorInputRef = useRef(null);



 const handleNomeKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault(); // Evita o comportamento padrão do "Enter" em um formulário
    if (valorInputRef.current) {
      valorInputRef.current.focus();
    }
  }
};

useEffect(() => {
  const handleKeyPress = (event) => {
    if (event.key === "F1") {
      event.preventDefault(); // Evita o comportamento padrão da tecla F1
      handleOpenModal(); // Chama a função para abrir o modal
    }
  };

  document.addEventListener('keydown', handleKeyPress);

  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, []);



const handleOpenModal = (fromCategoryButton) => {
  setValor('0,00');
  setOpenModal(true);

  setTimeout(() => {
    if (fromCategoryButton && categoryListRef.current) {
      categoryListRef.current.focus();
    } else if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, 100);
};





const handleValorKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();

    // Abre o modal de categorias
    setShowCarousel(true);

    setTimeout(() => {
      if (categoriaInputRef.current) {
        categoriaInputRef.current.focus();
      }
    }, 100);
  }
};




const handleKeyDownCategories = (e) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();

    let newIndex = categoriaSelecionadaIndex;

    switch (e.key) {
      case 'ArrowUp':
        newIndex = Math.max(categoriaSelecionadaIndex - 1, 0);
        break;
      case 'ArrowDown':
        newIndex = Math.min(categoriaSelecionadaIndex + 1, categorias.length - 1);
        break;
      default:
        break;
    }

    setCategoriaSelecionada(categorias[newIndex]);
    setCategoria(categorias[newIndex].nome);
    setCategoriaSelecionadaIndex(newIndex);

    if (categoryListRef.current && categoryListRef.current.children[newIndex]) {
      categoryListRef.current.children[newIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });

      // Remove a cor de fundo de todos os itens
      for (let i = 0; i < categoryListRef.current.children.length; i++) {
        categoryListRef.current.children[i].style.backgroundColor = '';
      }

      // Verifica o tipo da categoria e define a cor de fundo
      const tipoCategoria = categorias[newIndex].tipo;
      const corFundo = tipoCategoria === 'entrada' ? '#FF0000' : '#00FF00';

      // Define a cor de fundo apenas para o item selecionado
      categoryListRef.current.children[newIndex].style.backgroundColor = corFundo;
    }
  } else if (e.key === 'Enter') {
    e.preventDefault();
    nameInputRef.current.focus();
  }
};













  useEffect(() => {
    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();
    setData(currentDateTime);
  }, []);

  useEffect(() => {
   const categoriasRef = ref(database, 'categorias');
onValue(categoriasRef, (snapshot) => {
  const categoriasData = snapshot.val();
  if (categoriasData) {
    const categoriasArray = Object.keys(categoriasData).map((key) => ({
      id: key, // Inclui o ID da categoria
      nome: categoriasData[key].nome,
      tipo: categoriasData[key].tipo,
    }));
    setCategorias(categoriasArray);
  }
});

    if (categoryListRef.current) {
      setScrollPosition(categoryListRef.current.scrollTop);
    }
  }, []);

 

  const handleCloseModal = () => {
    setOpenModal(false);
    setNome('');
    setValor('');
    setData(currentDateTime);
    setCategoria('');
    setShowCarousel(false);
    setCategoriaClicada(false);
    setIsSaved(false);
    setCategoriaSelecionada({ nome: '', tipo: '' });
    setErrorMessage('');
    setFilteredItems([]);
  };

  const handleNomeChange = (e) => {
    const uppercaseName = e.target.value.toUpperCase();
    setNome(uppercaseName);
  };

  const handleValorChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^\d]/g, '');

    let formattedValue = '';

    if (sanitizedValue.length === 0) {
      formattedValue = '0,00';
    } else {
      let integerPart = sanitizedValue.slice(0, -2);
      const decimalPart = sanitizedValue.slice(-2);

      if (integerPart.length === 0) {
        integerPart = '0';
      } else {
        integerPart = parseInt(integerPart).toLocaleString('pt-BR');
      }

      formattedValue = `${integerPart},${decimalPart}`;
    }

    setValor(formattedValue);
  };

  const handleDataChange = (e) => {
    setData(e.target.value);
  };

  const handleCategoriaClick = (fromCategoryButton) => {
  setShowCarousel(true);
  setCategoriaClicada(true);

  if (fromCategoryButton && categoryListRef.current) {
    categoryListRef.current.focus();
  }
};



const handleEnterPress = () => {
  if (categoryListRef.current) {
    categoryListRef.current.focus();
    setCategoriaSelecionada(categorias[0]);
    setCategoria(categorias[0].nome);
  }
};




  const handleCarouselOptionClick = (option) => {
    const categoriaSelecionada = categorias.find((categoria) => categoria.nome === option);
    setCategoriaSelecionada(categoriaSelecionada);
    setCategoria(option);
  };

const handleSalvarClick = () => {
  if (nome === '') {
    setErrorMessage('Por favor, preencha o campo "Nome".');
  } else if (valor === '0,00') {
    setErrorMessage('Por favor, preencha o campo "Valor".');
  } else if (categoria === '') {
    setErrorMessage('Por favor, selecione uma categoria.');
  } else if (!categoriaSelecionada.id) {
    setErrorMessage('Categoria selecionada inválida. Por favor, selecione novamente.');
  } else {
    // Obtém o caminho de armazenamento no Firebase
    const storagePath = getStoragePath();

    // Adiciona o novo item no Firebase
    const dadosRef = ref(database, `itens/${storagePath}`);
    const newItemRef = push(dadosRef);
    const newItemId = newItemRef.key;

    const dataToSave = {
      id: newItemId,
      nome: nome,
      valor: valor,
      data: data,
      categoria: {
        id: categoriaSelecionada.id,
        nome: categoriaSelecionada.nome,
        tipo: categoriaSelecionada.tipo,
      },
    };

    // Salva os dados no nó do Firebase
    set(newItemRef, dataToSave)
      .then(() => {
        console.log('Novo item adicionado com o ID:', newItemId);

        setShowSuccessMessage(true);
        handleCloseModal();

        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);

        setNome('');
        setValor('0,00');
        setData(currentDateTime);
        setCategoriaSelecionada({ nome: '', tipo: '', id: '' }); // Reinicializa o estado da categoria selecionada

        // Atualiza o estado dos itens para incluir o novo item adicionado
        setItems((prevItems) => [...prevItems, dataToSave]);
setIsSaved(true);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  }

  setTimeout(() => {
    setErrorMessage('');
  }, 3000);
};


  const handleScrollUp = () => {
    if (categoryListRef.current) {
      categoryListRef.current.scrollTop -= 100;
      setScrollPosition(categoryListRef.current.scrollTop);
    }
  };

  const handleScrollDown = () => {
    if (categoryListRef.current) {
      categoryListRef.current.scrollTop += 100;
      setScrollPosition(categoryListRef.current.scrollTop);
    }
  };

  const history = useNavigate();

  const handleAdicionarClick = () => {
    history('/mobile-carousel');
  };

  useEffect(() => {
  setTimeout(() => {
    setIsSaved(false);
  }, 3000);
}, []);

const handleF1Help = () => {
  alert("Aperte para adicionar uma nota");
};

const handleF6Help = () => {
  toast.info("Pressione F6 para salvar.", {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
};



  return (
    <div className={isDarkMode ? '#5d6970' : ''} style={{ marginTop: isMobile ? '0' : '-70px' }}>


      {errorMessage && (
        <Typography
          variant="body1"
          align="right"
          sx={{
            position: 'fixed',
            top: '1rem',
            right: '1rem',
            padding: '0.5rem',
            borderRadius: '4px',
            backgroundColor: '#ff5252',
            color: '#ffffff',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 9999,
          }}
        >
          {errorMessage}
        </Typography>
      )}

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSidebarVisible ? 'flex-start' : 'center', marginLeft:  '-300px' }}>
{window.innerWidth > 1200 && (
 <div className={`mensagem ${timeOfDay}`}>
        <h1>{message}</h1>
      </div>
 )}
        {window.innerWidth > 1200 && (

          <IconButton
            style={{
              marginRight: '10px',
              marginLeft: '10px',
              background: '#FFC107',
              
              borderRadius: '8px',
              fontWeight: 'Bold',
        fontSize: '16px',
            }}
            onClick={handleF1Help}
          >
            F1
          </IconButton>
        )}

        {window.innerWidth > 1200? (
          <Button variant="contained" onClick={handleOpenModal}>
            ADICIONAR
          </Button>
        ) : (
          <Button className="add-button" variant="contained" onClick={handleAdicionarClick}>
            ADICIONAR
          </Button>
        )}

         {!isMobile && (
      <div>
        <Button
          style={{
            marginRight: '10px',
            marginLeft: '10px',
            background: '#FFC107',
            color: '#333333',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          onClick={handleF7Click}
        >
          F7
        </Button>
        <Button
          style={{
            background: '#FFC107',
            color: '#333333',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          onClick={handleF8Click}
        >
          F8
        </Button>
      </div>
    )}
 
      </div>

 <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity="info"
          sx={{
            background: '#f2f2f2', // Cor de fundo suave
            color: '#333333', // Cor do texto
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Sombra suave
            borderRadius: '8px', // Borda arredondada
          }}
        >
          F7: Volta para a página anterior | F8: Vai para a próxima página
        </MuiAlert>
      </Snackbar>




      
      {window.innerWidth > 1200 ? (
        <SavedItems isDarkMode={isDarkMode}  />
      ) : (
        <MobileSavedItems />
      )}

      <Modal 
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
          }}
        >
          <Box
  className={isDarkMode ? 'dark-mode' : ''}
  sx={{
    bgcolor: isDarkMode ? '#333' : 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '50%',
    position: 'relative',
    marginRight: '2rem',
    borderRadius: '8px',
    color: isDarkMode ? '#fff' : 'inherit', // Defina a cor do texto
  }}
>
            <IconButton
              onClick={handleCloseModal}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-title" variant="h6" component="h2" sx={{ fontFamily: 'sua-fonte-moderna', fontSize: '24px', fontWeight: 'bold', color: '#333', marginBottom: '8px' }}>
  Edição da Nota
</Typography>

            <form>
              <Box sx={{ mt: 2, width: '75%' }}>
  <TextField
    label="Nome"
    value={nome}
    onChange={handleNomeChange}
    fullWidth
    required
    inputRef={nameInputRef}
    onKeyDown={handleNomeKeyDown}
  />
</Box>

              <Box sx={{ mt: 2,width: '50%' }}>
                <TextField
  label="Valor"
  value={valor}
  onChange={handleValorChange}
  fullWidth
  required
  inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9]*',
  }}
  inputRef={valorInputRef}
  onKeyDown={handleValorKeyDown} 
/>

              </Box>
              <Box sx={{ mt: 2 , width: '25%' }}>
                <TextField
                  label="Data"
                  value={data}
                  onChange={handleDataChange}
                  fullWidth
                  required
                />
              </Box>
              <Box sx={{ mt: 2 , width: '40%' }}>
                <Button 
                    onClick={() => handleCategoriaClick(true)}
                    variant="outlined" 
                    fullWidth 
                    ref={categoriaInputRef}
                   onKeyDown={(e) => e.key === 'Enter' && handleEnterPress()}
                    >
                     

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {categoriaSelecionada.tipo === 'entrada' ? (
                      <Icon component={TrendingUp} />
                    ) : categoriaSelecionada.tipo === 'saida' ? (
                      <Icon component={TrendingDown} />
                    ) : null}
                    <Typography sx={{ marginLeft: '0.5rem' }}>
                      {categoriaSelecionada.nome} - {categoriaSelecionada.tipo}
                    </Typography>
                  </Box>
                  {showCarousel ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
              </Box>
              <Box sx={{ mt: 2 , width: '50%'}}>
<div >
  <ToastContainer />
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Button
      variant="contained"
      onClick={handleSalvarClick}
      fullWidth
      style={{
        
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '16px',
        marginRight: '10px',
      }}
    >
      Salvar (F6)
    </Button>
    <Button
      variant="contained"
      onClick={handleF6Help}
      style={{
        background: '#c7b61a',
        color: 'white',
        fontWeight: 'Bold',
        fontSize: '16px',
      }}
    >
    (F6)
    </Button>
  </div>
</div>

              </Box>
            </form>
          </Box>
          {showCarousel && (
            <Box className={isDarkMode ? 'dark-mode' : ''}
              sx={{
                bgcolor: isDarkMode ? '#333' : 'background.paper',
                boxShadow: 24,
                p: 2,
                width: '30%',
                borderRadius: '8px',
                color: isDarkMode ? '#fff' : 'inherit',
              }}
            >
              <IconButton onClick={handleScrollUp}>
                <KeyboardArrowUpIcon />
              </IconButton>
              <List ref={categoryListRef} style={{ height: '235px', overflow: 'auto' }} onKeyDown={handleKeyDownCategories} tabIndex={0}   role="listbox"  autoFocus>
                {categorias.map((categoriaData, index) => (
                  <ListItem
                    key={categoriaData.nome}
                    button
                    onClick={() => handleCarouselOptionClick(categoriaData.nome)}
                    selected={categoriaSelecionada.nome === categoriaData.nome}
className={categoriaData.tipo === 'entrada' ? 'entrada' : 'saida'}
tabIndex={index === 0 ? 0 : -1} 
                    sx={{
                      bgcolor:
                        categoriaData.nome === 'entrada'
                          ? '#00FF00'
                          : categoriaData.nome === 'saida'
                          ? '#FF0000'
                          : 'inherit',
                      '&:hover': {
                        bgcolor:
                          categoriaData.tipo === 'entrada'
                            ? '#00FF00'
                            : categoriaData.tipo === 'saida'
                            ? '#FF0000'
                            : 'inherit',
                      },
                    }}
                  >
                    {categoriaData.tipo === 'entrada' && (
                      <ListItemIcon>
                        <Icon component={TrendingUp} />
                      </ListItemIcon>
                    )}
                    {categoriaData.tipo === 'saida' && (
                      <ListItemIcon>
                        <Icon component={TrendingDown} />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={categoriaData.nome} secondary={categoriaData.tipo} />
                    {index === categorias.length - 1 && (
                      <Typography variant="caption" color="textSecondary" align="center">
                        Mais categorias
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </List>
              <IconButton onClick={handleScrollDown}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Modal>

      <List>
        {filteredItems.map((item) => (
          <ListItem key={item.id}>
            <ListItemText primary={item.nome} secondary={item.data} />
          </ListItem>
        ))}
      </List>

      {showSuccessMessage && (
        <Typography
          variant="body1"
          align="right"
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            padding: '0.5rem',
            borderRadius: '4px',
            backgroundColor: '#4caf50',
            color: '#ffffff',
          }}
        >
          Dados salvos com sucesso!
        </Typography>
      )}
    </div>
  );
}

export default Home;
