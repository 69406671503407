import React from 'react';

const CadastroOperadores = () => {
  const containerStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: 'auto',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#333',
  };

  const messageStyle = {
    fontSize: '16px',
    color: '#666',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Novas Funcionalidades de Cadastro de Operadores de Caixa</h2>
      <p style={messageStyle}>
        Estamos trabalhando duro para trazer a você novas e aprimoradas funcionalidades para o cadastro de operadores de caixa. Em breve, você terá acesso a um sistema mais eficiente e intuitivo para gerenciar seus operadores. Fique atento para mais atualizações!
      </p>
    </div>
  );
}

export default CadastroOperadores;

