import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Alert,
  Box,
  Modal,
} from '@mui/material';
import { ref, push, onValue, update, remove } from 'firebase/database';
import { database } from './firebaseConfig';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import './CriarCategoria.css';

function CriarCategoria() {
const [categoriaExistente, setCategoriaExistente] = useState(false);
  const [nomeCategoria, setNomeCategoria] = useState('');
  const [tipoCategoria, setTipoCategoria] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [erroNomeCategoria, setErroNomeCategoria] = useState('');
  const [erroTipoCategoria, setErroTipoCategoria] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [categoriaEditada, setCategoriaEditada] = useState({
    id: null,
    nome: '',
    tipo: '',
  });




  const [currentPage, setCurrentPage] = useState(1);
const [searchTerm, setSearchTerm] = useState(''); // Novo estado para o termo de busca
const itemsPerPage = 4;

  const handleNomeCategoriaChange = (e) => {
    const inputText = e.target.value.toUpperCase();
    setNomeCategoria(inputText);
  };

  const handleTipoCategoriaChange = (e) => {
    setTipoCategoria(e.target.value);
  };

 const handleSearchTermChange = (e) => {
  setSearchTerm(e.target.value);
};

  const limparMensagens = () => {
  setMensagem('');
  setErroNomeCategoria('');
  setErroTipoCategoria('');
  setCategoriaExistente(false);
};

const handleSubmit = (e) => {
  e.preventDefault();

  const categoriaJaExiste = categorias.some(categoria => categoria.nome === nomeCategoria);

  if (categoriaJaExiste) {
    setCategoriaExistente(true);
    setTimeout(() => {
      limparMensagens();
    }, 5000);
    return;
  }

  setCategoriaExistente(false);

  if (nomeCategoria === '') {
    setErroNomeCategoria('O campo "Nome da Categoria" não pode estar vazio.');
    setTimeout(() => {
      limparMensagens();
    }, 5000);
    return;
  } else {
    setErroNomeCategoria('');
  }

  if (tipoCategoria === '') {
    setErroTipoCategoria('Selecione o tipo de categoria (Entrada ou Saída).');
    setTimeout(() => {
      limparMensagens();
    }, 5000);
    return;
  } else {
    setErroTipoCategoria('');
  }

  const categoriaRef = ref(database, 'categorias');
  const novaCategoria = {
    nome: nomeCategoria,
    tipo: tipoCategoria,
  };

  push(categoriaRef, novaCategoria)
    .then(() => {
      setNomeCategoria('');
      setTipoCategoria('');
      setMensagem('Categoria cadastrada com sucesso!');
      setTimeout(() => {
        limparMensagens();
      }, 5000);
    })
    .catch((error) => {
      console.error('Erro ao cadastrar categoria:', error);
    });
};

  const loadCategorias = () => {
  const categoriaRef = ref(database, 'categorias');
  onValue(categoriaRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const categoriasArray = Object.entries(data).map(([key, value]) => ({
        id: key,
        nome: value.nome,
        tipo: value.tipo,
      }));

      // Filtrar categorias com base no termo de busca
      const categoriasFiltradas = categoriasArray.filter(
        (categoria) =>
          categoria.nome.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setCategorias(categoriasFiltradas);
    } else {
      setCategorias([]);
    }
  });
};

  useEffect(() => {
    loadCategorias();
  }, [searchTerm]); // Irá recarregar sempre que o termo de busca mudar


  const handleEditCategoria = (categoria) => {
    setCategoriaSelecionada(categoria);
    setCategoriaEditada({
      id: categoria.id,
      nome: categoria.nome,
      tipo: categoria.tipo,
    });
    setModalOpen(true);
  };

  const handleSaveEdicao = () => {
  let categoriaRef;

  if (categoriaEditada.id === null) {
    const novaCategoria = {
      nome: categoriaEditada.nome,
      tipo: categoriaEditada.tipo,
    };

    categoriaRef = ref(database, 'categorias');

    push(categoriaRef, novaCategoria)
      .then(() => {
        setModalOpen(false);
        setMensagem('Categoria cadastrada com sucesso!');
        setNomeCategoria('');
        setTipoCategoria('');
        setTimeout(() => {
          limparMensagens(); // Adiciona esta linha
        }, 5000);
      })
      .catch((error) => {
        console.error('Erro ao cadastrar categoria:', error);
      });
  } else {
      categoriaRef = ref(database, `categorias/${categoriaEditada.id}`);

      const updatedCategoria = {
        nome: categoriaEditada.nome,
        tipo: categoriaEditada.tipo,
      };

      update(categoriaRef, updatedCategoria)
      .then(() => {
        setModalOpen(false);
        setMensagem('Categoria editada com sucesso!');
        setNomeCategoria('');
        setTipoCategoria('');
        setTimeout(() => {
          limparMensagens(); // Adiciona esta linha
        }, 5000);
      })
      .catch((error) => {
        console.error('Erro ao editar categoria:', error);
      });
  }
};

  const handleDeleteCategoria = (categoria) => {
  const confirmDelete = window.confirm(`Tem certeza de que deseja excluir a categoria "${categoria.nome}"?`);
  if (confirmDelete) {
    const categoriaRef = ref(database, `categorias/${categoria.id}`);
    remove(categoriaRef)
      .then(() => {
        setMensagem('Categoria excluída com sucesso!');
        setTimeout(() => {
          limparMensagens(); // Adiciona esta linha
        }, 5000);
        loadCategorias();
      })
      .catch((error) => {
        console.error('Erro ao excluir categoria:', error);
      });
  }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categorias.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(categorias.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ marginTop: '-80px' }}>
      <Typography variant="h5" align="center">
        Criar Categorias
      </Typography>

{categoriaExistente && (
  <Box sx={{ marginTop: '8px' }}>
    <Alert severity="warning">
      Já existe uma categoria com o mesmo nome. Isso pode causar conflito.
    </Alert>
  </Box>
)}

      <Box sx={{ marginTop: '16px' }}>
        {mensagem && <Alert severity="success">{mensagem}</Alert>}
      </Box>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome da Categoria"
          value={nomeCategoria}
          onChange={handleNomeCategoriaChange}
          fullWidth
        />
        {erroNomeCategoria && (
          <Box sx={{ marginTop: '8px' }}>
            <Alert severity="error">{erroNomeCategoria}</Alert>
          </Box>
        )}

        <FormControl component="fieldset" sx={{ marginTop: '16px' }}>
          <div className="radio-group">
            <FormControlLabel
              value="entrada"
              control={<Radio />}
              label="Entrada"
              className="radio-label"
              checked={tipoCategoria === 'entrada'}
              onChange={handleTipoCategoriaChange}
            />
            <FormControlLabel
              value="saida"
              control={<Radio />}
              label="Saída"
              className="radio-label"
              checked={tipoCategoria === 'saida'}
              onChange={handleTipoCategoriaChange}
            />
          </div>
        </FormControl>

        {erroTipoCategoria && (
          <Box sx={{ marginTop: '8px' }}>
            <Alert severity="error">{erroTipoCategoria}</Alert>
          </Box>
        )}

       <Box sx={{ marginTop: '16px', marginEnd: '16px', marginBottom: '16px' }}>
  <Button type="submit" variant="contained">
    Cadastrar
  </Button>
</Box>

      </form>

<TextField
  label="Buscar Categoria"
  value={searchTerm}
  onChange={handleSearchTermChange}
  fullWidth
/>


      <Typography variant="h4" align="center">
        Lista de Categorias
      </Typography>
      <ul className="categoria-list">
        {currentItems.map((categoria) => (
          <li key={categoria.id} className="categoria-item">
            <span className="categoria-nome">{categoria.nome}</span>
            <span className="categoria-tipo">{categoria.tipo}</span>
            <Button startIcon={<EditIcon />} onClick={() => handleEditCategoria(categoria)}>
              Editar
            </Button>
            <Button startIcon={<DeleteIcon />} onClick={() => handleDeleteCategoria(categoria)}>
              Excluir
            </Button>
          </li>
        ))}
      </ul>

      <ul className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
          disabled={currentPage === 1}
        >
          <KeyboardArrowLeft />
        </button>

        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <button onClick={() => paginate(number)} className={`page-link ${currentPage === number ? 'active' : ''}`}>
              {number}
            </button>
          </li>
        ))}

        <button
          onClick={() => paginate(currentPage + 1)}
          className={`page-link ${currentPage === totalPages ? 'disabled' : ''}`}
          disabled={currentPage === totalPages}
        >
          <KeyboardArrowRight />
        </button>
      </ul>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div style={{
          width: '60%',
          minHeight: '300px',
          padding: '20px',
          backgroundColor: 'white',
          margin: 'auto',
          marginTop: '10%',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography variant="h6">Editar Categoria</Typography>
          <TextField
            label="Nome da Categoria"
            value={categoriaEditada.nome}
            onChange={(e) => setCategoriaEditada({ ...categoriaEditada, nome: e.target.value })}
            fullWidth
            style={{ marginBottom: '16px' }}
          />
          <FormControl component="fieldset">
            <RadioGroup
              value={categoriaEditada.tipo}
              onChange={(e) => setCategoriaEditada({ ...categoriaEditada, tipo: e.target.value })}
            >
              <FormControlLabel value="entrada" control={<Radio />} label="Entrada" />
              <FormControlLabel value="saida" control={<Radio />} label="Saída" />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleSaveEdicao} style={{ marginTop: '16px' }}>
            Salvar
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default CriarCategoria;

