  import React, { useState, useEffect,useRef } from 'react';
import {
  Typography,
  Box,
  Paper,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import { database } from './firebaseConfig';
import { ref, onValue, remove, set } from 'firebase/database';
import { CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { parse, isToday, startOfDay, endOfDay, isWithinInterval, isSameDay,format } from 'date-fns';
import InputAdornment from '@mui/material/InputAdornment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import Pagination from '@mui/material/Pagination';
import FilteredResults from './FilteredResults';
import './SavedItems.css';



function SavedItems({ isDarkMode, toggleDarkMode }) {
  const [filteredPage, setFilteredPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [allItems, setAllItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const itemsPerPage = 8;
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editedItemData, setEditedItemData] = useState({
    nome: '',
    valor: 0,
    data: '',
    categoria: { id: '', nome: '', tipo: '' },
  });

  const [editedCategoryData, setEditedCategoryData] = useState({
    nome: '',
    tipo: '',
  });

const nomeRef = useRef(null);
  const valorRef = useRef(null);
  const categoriaRef = useRef(null);


const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    if (document.activeElement === nomeRef.current) {
      valorRef.current.focus();
    } else if (document.activeElement === valorRef.current) {
      categoriaRef.current.focus();
    } else if (document.activeElement === categoriaRef.current) {
      nomeRef.current.focus();
    }
  }
};

useEffect(() => {
  document.body.addEventListener('keydown', handleKeyDown);

  return () => {
    document.body.removeEventListener('keydown', handleKeyDown);
  };
}, []);


const handleDateChange = (date) => {
  setSelectedDate(date);
};

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
       setItems([]); // Adicionado
    setCategories([]); // Adicionado

    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.toLocaleString('default', { month: 'long' });
    const selectedDay = String(selectedDate.getDate()).padStart(2, '0');

        const itemsRef = ref(database, `itens/${selectedYear}/${selectedMonth}/${selectedDay}`);
        const categoriesRef = ref(database, 'categorias');

        onValue(itemsRef, (snapshot) => {
          const itemsData = snapshot.val();

          if (itemsData) {
            const itemsArray = Object.entries(itemsData).map(([id, item]) => ({
              id,
              ...item,
            }));

            itemsArray.sort((a, b) => {
              const timeA = a.data.match(/(\d{2}):(\d{2})/);
              const timeB = b.data.match(/(\d{2}):(\d{2})/);

              const minutesA = parseInt(timeA[1]) * 60 + parseInt(timeA[2]);
              const minutesB = parseInt(timeB[1]) * 60 + parseInt(timeB[2]);

              if (minutesA < minutesB) return 1;
              if (minutesA > minutesB) return -1;
              return 0;
            });

            setItems(itemsArray);
          }
        });

        onValue(categoriesRef, (snapshot) => {
          const categoriesData = snapshot.val();
          if (categoriesData) {
            const categoriesArray = Object.entries(categoriesData).map(([id, category]) => ({
              id,
              ...category,
            }));
            setCategories(categoriesArray);
          }
        });


        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao obter os itens:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = items.slice(startIndex, endIndex);

  const handleEditItem = (itemId) => {
    const itemToEdit = items.find((item) => item.id === itemId);
    const { categoria } = itemToEdit;

    const { nome, tipo } = categoria;

    setEditedItemData({ ...itemToEdit });
    setEditedCategoryData({ nome, tipo });
    setEditItemId(itemId);
    setEditModalOpen(true);
  };

  const handleSaveEditedItem = () => {
    const editedItem = {
      ...editedItemData,
      categoria: { id: editedItemData.categoria.id, ...editedCategoryData },
    };

    const itemDate = parse(editedItem.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    const isTodayItem = isToday(itemDate);

    if (isTodayItem) {
      const updatedItem = {
        ...editedItemData,
        categoria: { id: editedItemData.categoria.id, ...editedCategoryData },
        data: formattedDate(new Date()),
      };

      const editedItemPath = `itens/${itemDate.getFullYear()}/${itemDate.toLocaleString('default', { month: 'long' })}/${String(itemDate.getDate()).padStart(2, '0')}/${editedItem.id}`;

      set(ref(database, editedItemPath), updatedItem)
        .then(() => {
          setEditModalOpen(false);
          setEditItemId(null);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        })
        .catch((error) => {
          console.error('Erro ao salvar as alterações:', error);
        });
    } else {
      const year = itemDate.getFullYear();
      const month = itemDate.toLocaleString('default', { month: 'long' });
      const day = String(itemDate.getDate()).padStart(2, '0');
      const editedItemPath = `itens/${year}/${month}/${day}/${editedItem.id}`;

      set(ref(database, editedItemPath), editedItem)
        .then(() => {
          setEditModalOpen(false);
          setEditItemId(null);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        })
        .catch((error) => {
          console.error('Erro ao salvar as alterações:', error);
        });
    }
  };

  const handleDeleteItem = (itemId) => {
    setSelectedItemId(itemId);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = String(currentDate.getDate()).padStart(2, '0');

    remove(ref(database, `itens/${year}/${month}/${day}/${selectedItemId}`))
      .then(() => {
        setDeleteConfirmationOpen(false);
        setShowSuccessMessage(true);

        const updatedItems = items.filter((item) => item.id !== selectedItemId);
        setItems(updatedItems);

        const updatedAllItems = allItems.filter((item) => item.id !== selectedItemId);
        setAllItems(updatedAllItems);

        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Erro ao excluir o item:', error);
      });
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
    setSelectedItemId(null);
  };

  function formattedDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }

  const handleCategorySelect = (categoryId) => {
    const selectedCategory = categories.find((category) => category.id === categoryId);
    setEditedCategoryData(selectedCategory);
    setEditedItemData({
      ...editedItemData,
      categoria: { id: categoryId, ...selectedCategory },
    });
  };

  const handleValorChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^\d]/g, '');

    let formattedValue = '';

    if (sanitizedValue.length === 0) {
      formattedValue = '';
    } else {
      let integerPart = sanitizedValue.slice(0, -2);
      const decimalPart = sanitizedValue.slice(-2);

      if (integerPart.length === 0) {
        integerPart = '0';
      } else {
        integerPart = parseInt(integerPart).toLocaleString('pt-BR');
      }

      formattedValue = `${integerPart},${decimalPart}`;
    }

    setEditedItemData((prevData) => ({
      ...prevData,
      valor: formattedValue,
    }));
  };

  const calculateTotal = () => {
    let total = 0;

    items.forEach(item => {
      const itemDate = parse(item.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
      if (isSameDay(itemDate, selectedDate)) {
        total += parseFloat(item.valor.replace('R$', '').replace('.', '').replace(',', '.'));
      }
    });

    return total.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  }


const handleYearChange = (year) => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(year);
    setSelectedDate(newDate);
  };

  const handleMonthChange = (month) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(month);
    setSelectedDate(newDate);
  };

  const CustomYearDropdown = ({ dropdownData, selected }) => (
    <div className="react-datepicker-year-dropdown">
      <div className="react-datepicker-year-dropdown__scroll-area">
        {dropdownData.map(yearOption => (
          <div
            className={`react-datepicker-year-option ${yearOption === selected ? 'react-datepicker-year-option--selected' : ''}`}
            key={yearOption}
            onClick={() => handleYearChange(yearOption)}
          >
            {yearOption}
          </div>
        ))}
      </div>
    </div>
  );

  const CustomMonthDropdown = ({ selected }) => (
    <div className="react-datepicker-month-dropdown">
      <div className="react-datepicker-month-dropdown__scroll-area">
        {Array.from({ length: 12 }, (_, i) => (
          <div
            className={`react-datepicker-month-option ${i === selected ? 'react-datepicker-month-option--selected' : ''}`}
            key={i}
            onClick={() => handleMonthChange(i)}
          >
            {new Date(0, i).toLocaleString(undefined, { month: 'long' })}
          </div>
        ))}
      </div>
    </div>
  );

  const years = Array.from({ length: 10000 }, (_, i) => new Date().getFullYear() - 5000 + i);



  const hasItemsForSelectedDate = displayedItems.filter(item => {
    const itemDate = parse(item.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    const isSelectedDate = isSameDay(itemDate, selectedDate);
    return isSelectedDate;
  }).length > 0;


  useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'F7' && page > 1) {
      setPage(prevPage => prevPage - 1);
      event.preventDefault();
    } else if (event.key === 'F8' && page < totalPages) {
      setPage(prevPage => prevPage + 1);
      event.preventDefault();
    }
  };

  document.body.addEventListener('keydown', handleKeyDown);

  return () => {
    document.body.removeEventListener('keydown', handleKeyDown);
  };
}, [page, totalPages]);

  useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'F7' || event.key === 'F8') {
      event.preventDefault();
    }
  };

  document.body.addEventListener('keydown', handleKeyDown);

  return () => {
    document.body.removeEventListener('keydown', handleKeyDown);
  };
}, []);


  return (
    <Box className={isDarkMode ? 'dark-mode2' : ''} sx={{ mt: 2 }} >
       <Grid  container spacing={2} alignItems="center">

   <DatePicker
  selected={selectedDate}
  onChange={date => setSelectedDate(date)}
  showYearDropdown
  yearDropdownItemNumber={30}
  scrollableYearDropdown
  dateFormat="dd/MM/yyyy"
  locale={ptBR}
  highlightDates={[new Date()]} // Destaca o dia atual
  customYearDropdown={(dropdownData, selected) => (
    <CustomYearDropdown
      dropdownData={years}
      selected={selected}
    />
  )}
  customMonthDropdown={() => <CustomMonthDropdown selected={selectedDate.getMonth()} />}
  customInput={
    <input
      type="text"
      value={selectedDate ? format(selectedDate, 'dd/MM/yyyy', { locale: ptBR }) : ''}
      readOnly
    />
  }
/>

  <Grid item sx={{ ml: 5, width: '40%' }}>
{searchTerm !== '' && (
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'red' }}>
        Você está vendo os resultados da pesquisa.
      </Typography>
    )}
    <TextField
      label="Buscar"
      value={searchTerm}
      onChange={handleSearchChange}
      fullWidth
      variant="outlined"
      sx={{ mb: 2, width: '100%' }}
    />
  </Grid>
  <Grid item>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, width: '100%' }}>
      Total: R$ {calculateTotal()}
    </Typography>
  </Grid>

</Grid>



      {searchTerm !== '' ? (
        <FilteredResults
  items={items}
  searchTerm={searchTerm}
  handleEditItem={handleEditItem}
  handleDeleteItem={handleDeleteItem}
  page={page} // Página atual
  itemsPerPage={itemsPerPage} // Itens por página
/>
      ) : (
        <>
{totalPages > 1 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
            </Box>
          )}
          {hasItemsForSelectedDate ? (
            <Grid className={isDarkMode ? 'dark-mode2' : ''} container spacing={4}>
              {displayedItems
                .filter(item => {
                  const itemDate = parse(item.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
                  const isSelectedDate = isSameDay(itemDate, selectedDate);
                  return isSelectedDate;
                })
                .slice((filteredPage * itemsPerPage) - itemsPerPage, filteredPage * itemsPerPage)
                .map((item) => (
                  <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Paper 
                      className={isDarkMode ? 'dark-mode' : ''}
                      sx=
                      {{ p: 2, 
                   bgcolor: isDarkMode ? ' #5d7e91' : 'background.paper',
                    margin: ' auto',
                 minHeight: '200px',
                   display: 'flex',
             flexDirection: 'column',
            justifyContent: 'space-between',
color: isDarkMode ? '#fff' : 'inherit',

 }}>
                      <div>
  <Typography variant="subtitle1">
    <span style={{ backgroundColor: isDarkMode ? '#102024' : 'yellow' }}>Nome:</span> {item.nome}
    <br />
    <span style={{ backgroundColor: isDarkMode ? '#102024' : 'yellow' }}>Valor:</span> {item.valor}
    <br />
    <span style={{ backgroundColor: isDarkMode ? '#102024' : 'yellow' }}>Data:</span> {item.data}
    <br />
    <span style={{ backgroundColor: isDarkMode ? '#102024' : 'yellow' }}>Categoria:</span>{' '}
    {item.categoria ? item.categoria.nome : 'N/A'} -{' '}
    {item.categoria ? item.categoria.tipo : 'N/A'}
  </Typography>
</div>

                      <div>
                        <Stack direction="row" justifyContent="flex-end">
                          <IconButton color="primary" onClick={() => handleEditItem(item.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="primary" onClick={() => handleDeleteItem(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </div>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              Nenhum item encontrado para a data selecionada.
            </Typography>
          )}
          
        </>
      )}


      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir esta nota?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Editar Item</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome"
            value={editedItemData.nome}
            onChange={(e) => setEditedItemData({ ...editedItemData, nome: e.target.value })}
            fullWidth
            required
            variant="outlined"
            sx={{
              marginBottom: '0.5rem',
            }}
 inputRef={nomeRef}
          />
        <TextField
  label="Valor"
  value={editedItemData.valor}
  onChange={handleValorChange}
  fullWidth
  required
  variant="outlined"
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        R$
      </InputAdornment>
    ),
  }}
  sx={{ marginBottom: '1rem' }}
inputRef={valorRef}
/>

          <Stack direction="row" spacing={2} alignItems="center" sx={{ marginBottom: '1rem', color: 'rgba(0, 0, 0, 0.7)' }}>
            <Typography variant="subtitle1" sx={{ marginRight: '0.5rem' }}>
              Data Antiga:
            </Typography>
            <Typography variant="body1">{editedItemData.data}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ marginBottom: '1rem', color: 'rgba(0, 0, 0, 0.7)' }}>
            <Typography variant="subtitle1" sx={{ marginRight: '0.5rem' }}>
              Data Atual:
            </Typography>
            <Typography variant="body1">{formattedDate(new Date())}</Typography>
          </Stack>

         <FormControl fullWidth required variant="outlined" sx={{ marginBottom: '1rem' }}>
  <InputLabel>SELECIONE CATEGORIA</InputLabel>
  <Select
    value={editedCategoryData.id}
    onChange={(e) => handleCategorySelect(e.target.value)}
    label="Categoria"
 inputRef={categoriaRef}
  >
   
    {categories.map((category) => (
      <MenuItem key={category.id} value={category.id}>
        {category.nome}
      </MenuItem>
    ))}
  </Select>
</FormControl>

{/* Exibindo os dados da categoria selecionada */}
<FormControl>
  {/* Nome da Categoria */}
  <Stack direction="row" spacing={2} alignItems="center" sx={{ marginBottom: '0.5rem' }}>
    <Typography variant="subtitle1" sx={{ marginRight: '0.5rem' }}>
      Nome da Categoria:
    </Typography>
    <Typography variant="body1">{editedCategoryData.nome}</Typography>
  </Stack>

  {/* Tipo da Categoria */}
  <Stack direction="row" spacing={2} alignItems="center">
    <Typography variant="subtitle1" sx={{ marginRight: '0.5rem' }}>
      Tipo da Categoria:
    </Typography>
    <Typography variant="body1">{editedCategoryData.tipo}</Typography>
  </Stack>
</FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEditedItem} color="primary" autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {showSuccessMessage && (
        <Box
          sx={{
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            backgroundColor: 'green',
            color: 'white',
            padding: '1rem',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
            zIndex: 9999,
          }}
        >
          Alterações salvas com sucesso.
        </Box>
      )}
    </Box>
  );
}

export default SavedItems;


