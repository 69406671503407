import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { Grid, Box, List, ListItemButton, ListItemText, ListItemIcon, BottomNavigation, BottomNavigationAction, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print'; 
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import TimelineIcon from '@mui/icons-material/Timeline';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import { database } from './components/firebaseConfig'; // Importe o database do arquivo correto
import { ref, onValue } from 'firebase/database';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SobreMobile from './components/SobreMobile'; // Importe o componente SobreMobile
import ImprimirTela from './components/ImprimirTela';
import Home from './components/Home';
import CriarCategoria from './components/CriarCategoria';
import RelatorioDia from './components/RelatorioDia';
import RelatorioDiaMobile from './components/RelatorioDiaMobile'; // Importe o componente RelatorioDiaMobile
import CadastroOperadores from './components/CadastroOperadores';
import Sobre from './components/Sobre';
import MobileCarousel from './components/MobileCarousel';
import CriarCategoriaMobile from './components/CriarCategoriaMobile'; // Importe o componente CriarCategoriaMobile
import './App.css';

function Sidebar({ isDarkMode, toggleDarkMode }) {
  const location = useLocation();
  const currentPage = location.pathname.substring(1);
 const [hour, setHour] = useState('');
const [date, setDate] = useState('');

  const [isServerOnline, setIsServerOnline] = useState(true);

const backgroundStyle = {
  border: '1px solid #ccc',  
  padding: '10px',           
  borderRadius: '4px',       
  textAlign: 'center',
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
};

if (isServerOnline) {
  backgroundStyle.backgroundColor = '#b8e0d9'; // Cor quando online
} else {
  backgroundStyle.backgroundColor = '#ebd3d3'; // Cor quando offline
}

 // Função para obter a hora e a data de Brasília
function getHourAndDateInBrasilia() {
  const now = new Date();
  const brasiliaTimezoneOffset = -3; // Fuso horário de Brasília
  const brasiliaTime = new Date(now.getTime() + brasiliaTimezoneOffset * 60 * 60 * 1000);

  const hour = brasiliaTime.toLocaleTimeString('pt-BR');
  const date = brasiliaTime.toLocaleDateString('pt-BR');

  return { hour, date };
}

   // Atualiza a hora e a data a cada segundo
useEffect(() => {
  const interval = setInterval(() => {
    const { hour, date } = getHourAndDateInBrasilia();
    setHour(hour);
    setDate(date);
  }, 1000); // Atualiza a cada 1 segundo

  return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
}, []);


  // Renderização condicional com base na disponibilidade do servidor
  let serverStatusDisplay;
if (isServerOnline) {
  serverStatusDisplay = (
    <div style={{ ...backgroundStyle, color: 'green' }}>
  <CheckCircleIcon /> Servidor On <br />
  Hora de Brasília: {hour} <br />
  Data de Brasília: {date}
</div>

  );
} else {
  serverStatusDisplay = (
    <div style={{ ...backgroundStyle, color: 'red' }}>
      <CancelIcon /> Servidor Off <br />
      Data Local:   {new Date().toLocaleDateString()}  <br /> 
    
      Hora Local: <br />
     {new Date().toLocaleTimeString()} 
    </div>
  );
}



  useEffect(() => {
    const serverStatusRef = ref(database, '.info/connected');
    onValue(serverStatusRef, (snapshot) => {
      const isOnline = snapshot.val();
      setIsServerOnline(isOnline);
    });
  }, []);


   const darkModeStyles = {
    color: '#fff',
    backgroundColor: '#333',
  };

  const darkModeIcon = {
    color: '#fff',
  };



  return (
   <Box 
      sx={{
        backgroundColor: isDarkMode ? '#333' : '#fff',
        padding: '10px',
        height: '90vh',
        color: isDarkMode ? '#fff' : 'inherit',
        
      }}
    >
      <List component="nav">
        <ListItemButton
          component={Link}
          to="/"
          selected={currentPage === ''}
          sx={{
            color: currentPage === '' ? 'primary.main' : 'inherit',
            '& .MuiListItemIcon-root': {
              color: isDarkMode ? '#fff' : 'inherit',
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon sx={isDarkMode ? darkModeIcon : {}} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton
  component={Link}
  to="/criar-categoria"
  selected={currentPage === 'criar-categoria'}
  sx={{
    color: currentPage === 'criar-categoria' ? 'primary.main' : 'inherit',
    '& .MuiListItemIcon-root': {
      color: isDarkMode ? '#fff' : 'inherit',
    },
  }}
>
  <ListItemIcon>
    <CategoryIcon sx={isDarkMode ? darkModeIcon : {}} />
  </ListItemIcon>
  <ListItemText primary="Criar Categorias" />
</ListItemButton>
        <ListItemButton
  component={Link}
  to="/relatorio-dia"
  selected={currentPage === 'relatorio-dia'}
  sx={{
    color: currentPage === 'relatorio-dia' ? 'primary.main' : 'inherit',
    '& .MuiListItemIcon-root': {
      color: isDarkMode ? '#fff' : 'inherit',
    },
  }}
>
  <ListItemIcon>
    <TimelineIcon sx={isDarkMode ? darkModeIcon : {}} />
  </ListItemIcon>
  <ListItemText primary="Gráficos" />
</ListItemButton>
        <ListItemButton
  component={Link}
  to="/imprimir-tela"
  selected={currentPage === 'imprimir-tela'}
  sx={{
    color: currentPage === 'imprimir-tela' ? 'primary.main' : 'inherit',
    '& .MuiListItemIcon-root': {
      color: isDarkMode ? '#fff' : 'inherit',
    },
  }}
>
  <ListItemIcon>
    <PrintIcon sx={isDarkMode ? darkModeIcon : {}} />
  </ListItemIcon>
  <ListItemText primary="Imprimir relatório do dia" />
</ListItemButton>

<ListItemButton
  component={Link}
  to="/sobre"
  selected={currentPage === 'sobre'}
  sx={{
    color: currentPage === 'sobre' ? 'primary.main' : 'inherit',
    '& .MuiListItemIcon-root': {
      color: isDarkMode ? '#fff' : 'inherit',
    },
  }}
>
  <ListItemIcon>
    <InfoIcon sx={isDarkMode ? darkModeIcon : {}} />
  </ListItemIcon>
  <ListItemText primary="Sobre" />
</ListItemButton>
<ListItemButton
  component={Link}
  to="/cadastros-perfil"
  selected={currentPage === 'cadastros-perfil'}
  sx={{
    color: currentPage === 'cadastros-perfil' ? 'primary.main' : 'inherit',
    '& .MuiListItemIcon-root': {
      color: isDarkMode ? '#fff' : 'inherit',
    },
  }}
>
  <ListItemIcon>
    <PersonIcon sx={isDarkMode ? darkModeIcon : {}} />
  </ListItemIcon>
  <ListItemText primary="Cadastros de Perfil" />
</ListItemButton>

<Box sx={{ height: '5px' }} /> 
<Button
  variant="contained"
  color="primary"
  onClick={toggleDarkMode}
  sx={{
    backgroundColor: isDarkMode ? 'blue' : null, // Corrigido para 'blue'
  }}
>
  Modo Noturno
  <Brightness2Icon
    style={{ marginLeft: '8px', color: isDarkMode ? '#fff' : 'inherit' }}
  />
</Button>
{serverStatusDisplay}

      </List>
    </Box>
  );
}


function MainContent({ isDarkMode, isSidebarVisible}) {
  return (
    <Box sx={{ backgroundColor: isDarkMode ? '#5d6970' : '#f0f0f0', padding: '20px',height: '87vh',  marginLeft: '-10px',marginRight: '-0px'}}>
      <Routes>
        <Route path="/" element={<Home isDarkMode={isDarkMode} isSidebarVisible={isSidebarVisible} />} />
        <Route path="/criar-categoria" element={<CriarCategoria />} />
        <Route path="/relatorio-dia" element={<RelatorioDia />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/imprimir-tela" element={<ImprimirTela />} />
        <Route path="/cadastros-perfil" element={<CadastroOperadores/>} />
      </Routes>
    </Box>
  );
}

function MobileLayout() {
  const location = useLocation();
  const currentPage = location.pathname.substring(1);

  return (
    <Box sx={{ backgroundColor: '#ffffff', padding: '20px' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/criar-categoria" element={<CriarCategoriaMobile />} /> {/* Alterado para usar CriarCategoriaMobile */}
        <Route path="/relatorio-dia" element={<RelatorioDiaMobile />} /> {/* Usar o RelatorioDiaMobile no layout móvel */}
           <Route path="/sobre" element={<SobreMobile />} />
        <Route path="/mobile-carousel" element={<MobileCarousel />} />
      </Routes>
      <BottomNavigation showLabels sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
        <BottomNavigationAction
          component={Link}
          to="/"
          label="Home"
          value="home"
          icon={<HomeIcon />}
          sx={{ color: currentPage === '' ? 'primary.main' : 'inherit' }}
        />
        <BottomNavigationAction
          component={Link}
          to="/criar-categoria"
          label="Criar Categorias"
          value="criar-categoria"
          icon={<CategoryIcon />}
          sx={{ color: currentPage === 'criar-categoria' ? 'primary.main' : 'inherit' }}
        />
        <BottomNavigationAction
          component={Link}
          to="/relatorio-dia"
          label="Relatório do Dia"
          value="relatorio-dia"
          icon={<TimelineIcon />}
          sx={{ color: currentPage === 'relatorio-dia' ? 'primary.main' : 'inherit' }}
        />
        <BottomNavigationAction
          component={Link}
          to="/sobre"
          label="Sobre"
          value="sobre"
          icon={<InfoIcon />}
          sx={{ color: currentPage === 'sobre' ? 'primary.main' : 'inherit' }}
        />
      </BottomNavigation>
    </Box>
  );
}



function App() {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode') === 'true'
  );

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
  };

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode === 'true');
    }
  }, []);

  return (
    <Router>
      <div style={{ backgroundColor: isDarkMode ? '#333' : '#f0f0f0' }}>
        {!isMobile && (
          <Button variant="contained" color="primary" onClick={toggleSidebar} style={{ margin: '10px' }}>
            {isSidebarVisible ? 'Ocultar Barra' : 'Mostrar Barra'}
          </Button>
        )}
      </div>
      {isMobile ? (
        <MobileLayout />
      ) : (
        <Grid container>
          {isSidebarVisible && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
            <Sidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
            </Grid>
          )}
          <Grid item xs={12} sm={isSidebarVisible ? 8 : 12} md={isSidebarVisible ? 9 : 12} lg={isSidebarVisible ? 10 : 12}>
            <MainContent isDarkMode={isDarkMode} /> {/* Passando o estado isDarkMode para MainContent */}
          </Grid>
        </Grid>
      )}
    </Router>
  );
}

export default App;
