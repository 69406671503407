import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { database } from './firebaseConfig';
import { ref, onValue } from 'firebase/database';
import logo from './logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ImprimirTela = () => {
  const [printData, setPrintData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isScrollActive, setIsScrollActive] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F4') {
        handlePrintReceipt();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const itemsRef = ref(database, 'itens');

    if (!selectedDate) {
      const today = new Date();
      const formattedDate = format(today, 'dd/MM/yyyy');

      const onDataChange = (snapshot) => {
        const itemsData = snapshot.val();
        if (itemsData) {
          const flattenedData = Object.values(itemsData)
            .flatMap((ano) =>
              Object.values(ano).flatMap((mes) =>
                Object.values(mes).flatMap((dia) => Object.values(dia))
              )
            );

          const filteredData = flattenedData.filter((item) => item.data.split(', ')[0] === formattedDate);

          setPrintData(filteredData);
          setIsScrollActive(filteredData.length > 5);
        } else {
          setPrintData([]);
          setIsScrollActive(false);
        }
      };

      const itemsListener = onValue(itemsRef, onDataChange);

      return () => {
        itemsListener();
      };
    } else {
      const formattedDate = format(selectedDate, 'dd/MM/yyyy');

      const onDataChange = (snapshot) => {
        const itemsData = snapshot.val();
        if (itemsData) {
          const flattenedData = Object.values(itemsData)
            .flatMap((ano) =>
              Object.values(ano).flatMap((mes) =>
                Object.values(mes).flatMap((dia) => Object.values(dia))
              )
            );

          const filteredData = flattenedData.filter((item) => item.data.split(', ')[0] === formattedDate);

          setPrintData(filteredData);
          setIsScrollActive(filteredData.length > 5);
        } else {
          setPrintData([]);
          setIsScrollActive(false);
        }
      };

      const itemsListener = onValue(itemsRef, onDataChange);

      return () => {
        itemsListener();
      };
    }
  }, [selectedDate]);


  const handlePrintReceipt = () => {
  try {
    const contentDiv = document.createElement('div');
    contentDiv.style.width = '300px';
    contentDiv.style.padding = '16px';
    contentDiv.style.borderLeft = '1px solid #ccc';
    contentDiv.style.borderRight = '1px solid #ccc';

    // Adicione o cabeçalho "Notas do Dia"
    const header = document.createElement('div');
    header.innerHTML = '<h2 style="text-align: center; margin-bottom: 10px;">Notas do Dia</h2>';
    contentDiv.appendChild(header);

    printData.forEach(item => {
      const itemDiv = document.createElement('div');
      itemDiv.innerHTML = `------------------------------------------------------<br>
                          Data/Hora: ${item.data}<br>
                          Nome: ${item.nome || ''}<br>
                          Categoria: ${item.categoria ? item.categoria.nome : ''}<br>
                          Tipo: ${item.categoria ? item.categoria.tipo : ''}<br>
                          Valor: ${formatCurrency(item.valor || '0')}<br>
                          ------------------------------------------------------<br>`;

      contentDiv.appendChild(itemDiv);
    });

    const developedBy = document.createElement('div');
    developedBy.innerHTML = 'Sistema de controle financeiro desenvolvido por lázaro';
    developedBy.style.textAlign = 'center';
    developedBy.style.marginTop = '10px';

    contentDiv.appendChild(developedBy);

    const logoContainer = document.createElement('div');
    logoContainer.style.textAlign = 'center';
    logoContainer.style.marginTop = '5px'; 

    const logoImage = new Image();
    logoImage.src = logo;
    logoImage.style.width = '100px'; 
    logoImage.style.height = '100px'; 
    logoImage.style.display = 'inline-block'; 

    logoContainer.appendChild(logoImage);

    contentDiv.appendChild(logoContainer);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    const iframeWindow = iframe.contentWindow;

    iframeWindow.document.open();
    iframeWindow.document.write(contentDiv.outerHTML);
    iframeWindow.document.close();

    setTimeout(() => {
      iframeWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  } catch (error) {
    console.error('Erro ao imprimir:', error);
  }
};



   const formatCurrency = (value) => {
    const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return value;
  };


 const handleButtonClick = () => {
    handlePrintReceipt();
    toast.info('Pressione F4 para imprimir');
  };


  return (
    <div className="relatorio-dia">
      <Typography variant="h4" gutterBottom style={{fontWeight: 'bold',
          fontSize: '40px'}}>
        Relatório do dia por Categorias
      </Typography>

       <div style={{ display: 'flex', alignItems: 'center'}}>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione a data"
        />
     <div style={{marginLeft: '20px'}}>
<div
        style={{
          marginLeft: '20px',
          fontWeight: 'bold',
          fontSize: '16px',
          color: 'gray',
          background: '#ffeb3b',
          padding: '10px',
          borderRadius: '5px',
          margin: '5px 10px',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick}
      >
        F4
      </div>
        <ToastContainer position="top-right" />
</div>
      </div>


      {printData && printData.length > 0 ? (
        <div style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'auto' }}>
          <div style={{ marginBottom: '10px' }}>
            <Typography variant="h6" gutterBottom>
              Comprovante de Compras
            </Typography>
            <Typography variant="body1">Data: {selectedDate && format(selectedDate, 'dd/MM/yyyy')}</Typography>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data/Hora</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {printData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.data}</TableCell>
                    <TableCell>{item.nome || ''}</TableCell>
                    <TableCell>{item.categoria ? item.categoria.nome : ''}</TableCell>
                    <TableCell>{item.categoria ? item.categoria.tipo : ''}</TableCell>
                    <TableCell>{formatCurrency(item.valor || '0')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <p>Nenhum dado disponível para a data selecionada.</p>
      )}

{isScrollActive && (
        <div style={{ textAlign: 'center', marginTop: '10px', color: 'gray', fontSize: '14px' }}>
          Há mais notas disponíveis. Role para baixo para visualizar.
        </div>
      )}

 <Button onClick={handlePrintReceipt} variant="contained" color="primary" style={{ marginTop: '20px' }}>
      Imprimir Comprovante
    </Button>
    </div>
  );
};

export default ImprimirTela;
