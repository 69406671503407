import React from 'react';
import './CustomPaginationMobile.css';

const CustomPaginationMobile  = ({ totalPages, currentPage, goToPage }) => {
  const renderPageNumbers = () => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(
      <span
        key={i}
        onClick={() => goToPage(i)}
        className={`page-number ${i === currentPage ? 'active' : ''}`}
        style={{ margin: '0 5px' }} // Adicionando estilo de margem
      >
        {i}
      </span>
    );
  }
  return pageNumbers;
};

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
      <span
  onClick={() => goToPage(currentPage - 1)}
  className={`arrow ${currentPage === 1 ? 'disabled' : ''}`}
  style={{ 
    cursor: 'pointer', 
    margin: '0 5px', 
    color: '#555', 
    fontSize: '1.5rem', 
    fontWeight: 'bold',
    opacity: currentPage === 1 ? '0.5' : '1'
  }}
>
  {'<'}
</span>

{renderPageNumbers()}

<span
  onClick={() => goToPage(currentPage + 1)}
  className={`arrow ${currentPage === totalPages ? 'disabled' : ''}`}
  style={{ 
    cursor: 'pointer', 
    margin: '0 5px', 
    color: '#555', 
    fontSize: '1.5rem', 
    fontWeight: 'bold',
    opacity: currentPage === totalPages ? '0.5' : '1'
  }}
>
  {'>'}
</span>
    </div>
  );
};

export default CustomPaginationMobile;

