import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyBcXqYSeZtIayfYGQ_18oaKst1f7w3VzC0",
  authDomain: "larscanner-142ba.firebaseapp.com",
  databaseURL: "https://larscanner-142ba-default-rtdb.firebaseio.com",
  projectId: "larscanner-142ba",
  storageBucket: "larscanner-142ba.appspot.com",
  messagingSenderId: "711584292312",
  appId: "1:711584292312:web:6fb955dd639ba25d69e6ad",
  measurementId: "G-DZH7LB5RWD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export {app, database};
