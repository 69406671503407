import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, Cell } from 'recharts';
import { database } from './firebaseConfig';
import { ref, onValue } from 'firebase/database';
import './RelatorioDia.css';

const RelatorioDia = () => {
  const [data, setData] = useState([]);
  const today = new Date().toLocaleDateString('pt-BR'); // Obtém a data atual no formato DD/MM/YYYY

  useEffect(() => {
    const itemsRef = ref(database, 'itens');
    const onDataChange = (snapshot) => {
      const itemsData = snapshot.val();
      if (itemsData) {
        const flattenedData = Object.values(itemsData)
          .flatMap((ano) =>
            Object.values(ano).flatMap((mes) =>
              Object.values(mes).flatMap((dia) => Object.values(dia))
            )
          );

        // Filtrando os dados do dia atual
        const filteredData = flattenedData.filter((item) => item.data.split(', ')[0] === today);

        // Agrupando os itens filtrados por categoria e somando os valores
        const groupedData = filteredData.reduce((result, item) => {
          const key = item.categoria.nome; // Usando o nome da categoria como chave
          if (!result[key]) {
            result[key] = {
              categoria: item.categoria.nome,
              valor: parseFloat(item.valor.replace('R$ ', '').replace('.', '').replace(',', '.')),
              cor: getRandomColor(), // Gerando uma cor aleatória para cada categoria
            };
          } else {
            result[key].valor += parseFloat(item.valor.replace('R$ ', '').replace('.', '').replace(',', '.'));
          }
          return result;
        }, {});

        // Convertendo o objeto agrupado em um array de objetos
        const dataArray = Object.values(groupedData);

        setData(dataArray);
      } else {
        setData([]);
      }
    };

    const unsubscribe = onValue(itemsRef, onDataChange, {
      onlyOnce: true,
    });

    return () => {
      unsubscribe();
    };
  }, [today]);

  // Função para gerar uma cor aleatória
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div>
      <h2>Gráfico de Itens por Categoria do Dia Atual</h2>
      <BarChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="categoria" />
        <YAxis tickFormatter={(value) => `R$ ${value}`} />
        <Tooltip formatter={(value) => `R$ ${value}`} />
        <Legend />
        <Bar dataKey="valor">
          {data.map((item) => (
            <Cell key={item.categoria} fill={item.cor} />
          ))}
        </Bar>
      </BarChart>

      <div className="table-container">
        <table className="modern-table">
          <thead>
            <tr>
              <th>Categoria</th>
              <th className="valor-header">Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.categoria}>
               <td className="categoria-cell" style={{ backgroundColor: item.cor }}>{item.categoria}</td>
<td className="valor-cell">R$ {item.valor.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RelatorioDia;

