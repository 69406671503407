import React, { useEffect } from 'react';
import { Typography, Grid, Link } from '@mui/material';
import { Business, AccountBalance, MonetizationOn, SettingsApplications } from '@mui/icons-material';
import packageJson from '../../package.json';
import './Sobre.css';

function Sobre() {
  const appVersion = packageJson.version;

useEffect(() => {
    const animatedBorders = document.querySelectorAll('.animated-border');

    animatedBorders.forEach(border => {
      const randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
      border.style.borderImage = `linear-gradient(45deg, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}, ${randomColor}) 1`;
      border.style.borderRadius = '25px'; // Adicionando bordas arredondadas
    });
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="flex-start" style={{ height: '100vh',  color: '#000', marginTop: '-70px' }}>

      <Grid item xs={12} md={8} lg={6}>
        <Typography variant="h4" align="center" gutterBottom style={{ fontSize: '1.5em', marginBottom: '1em', textTransform: 'uppercase' }}>Sobre nós</Typography>
       <Grid container spacing={2} justifyContent="center" alignItems="stretch">
  {[
    { 
      icon: <div className="animated-border"><SettingsApplications fontSize="large" /></div>, 
      title: 'Missão', 
      content: 'A missão da VELB Studios é ajudar comerciantes a registrar com precisão suas atividades, facilitando o gerenciamento e a tomada de decisões estratégicas.' 
    },
    { 
      icon: <div className="animated-border"><AccountBalance fontSize="large" /></div>, 
      title: 'Visão', 
      content: 'Buscamos evoluir as soluções digitais diariamente, oferecendo ferramentas que economizam tempo e aumentam a produção para impulsionar o sucesso dos nossos clientes.' 
    },
    { 
      icon: <div className="animated-border"><MonetizationOn fontSize="large" /></div>, 
      title: 'Valores', 
      content: 'Nossos valores incluem eficiência, inovação e compromisso com o crescimento dos negócios dos nossos clientes.' 
    },
  ].map((item, index) => (
    <Grid item xs={12} sm={4} key={index}> {/* Alterei o tamanho da grid para ocupar 4 colunas */}
      <div className="animated-border" style={{ marginBottom: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '15px', borderRadius: '25px', border: '1px solid #fff' }}>
    {item.icon}
    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '1.2em', marginTop: '1em', textTransform: 'uppercase' }}>{item.title}</Typography>
    <Typography align="center" style={{ fontSize: '1em' }}>{item.content}</Typography>
  </div>
</div>



    </Grid>
  ))}
</Grid>

        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <Typography variant="subtitle2" style={{ fontSize: '0.9em' }}>VELB SISTEMAS @corporation 2023 {appVersion}</Typography>
          <Link href="/termos-de-uso" style={{ color: '#fff', textDecoration: 'none', margin: '0 0.5em' }}>Termos de Uso</Link> • <Link href="/politica-de-privacidade" style={{ color: '#fff', textDecoration: 'none', margin: '0 0.5em' }}>Política de Privacidade</Link>
          <Typography variant="subtitle2" style={{ fontSize: '0.9em' }}>© 2023 VELB • SISTEMAS PARA SOLUÇÕES DIGITAIS LTDA • CNPJ: 27.864.392/0001-93</Typography>
        </div>
      </Grid>
    </Grid>
  );
}

export default Sobre;

