import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { database } from './firebaseConfig';
import { ref, onValue } from 'firebase/database';
import './RelatorioDiaMobile.css';

const RelatorioDiaMobile = () => {
  const [data, setData] = useState([]);
  const [corCategoriaSelecionada, setCorCategoriaSelecionada] = useState(null);
  const today = new Date().toLocaleDateString('pt-BR'); // Obtém a data atual no formato DD/MM/YYYY

  useEffect(() => {
    const itemsRef = ref(database, 'itens');
    const onDataChange = (snapshot) => {
      const itemsData = snapshot.val();
      if (itemsData) {
        const flattenedData = Object.values(itemsData)
          .flatMap((ano) =>
            Object.values(ano).flatMap((mes) =>
              Object.values(mes).flatMap((dia) => Object.values(dia))
            )
          );

        const filteredData = flattenedData.filter((item) => item.data.split(', ')[0] === today);

        const groupedData = filteredData.reduce((result, item) => {
          const key = item.categoria.nome;
          if (!result[key]) {
            result[key] = {
              categoria: item.categoria.nome,
              valor: parseFloat(item.valor.replace('R$ ', '').replace('.', '').replace(',', '.')),
              cor: getRandomColor(),
            };
          } else {
            result[key].valor += parseFloat(item.valor.replace('R$ ', '').replace('.', '').replace(',', '.'));
          }
          return result;
        }, {});

        const dataArray = Object.values(groupedData);

        setData(dataArray);
      } else {
        setData([]);
      }
    };

    const unsubscribe = onValue(itemsRef, onDataChange, {
      onlyOnce: true,
    });

    return () => {
      unsubscribe();
    };
  }, [today]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Obtemos os dados do ponto
    const corCategoriaSelecionada = data.cor; // Pegamos a cor da categoria

    return (
      <div className="custom-tooltip">
        <p style={{ color: corCategoriaSelecionada }}>Categoria: {data.categoria}</p>
        <p style={{ color: corCategoriaSelecionada }}>Valor: R$ {data.valor.toFixed(2)}</p>
      </div>
    );
  }

  return null;
};

  useEffect(() => {
    const handleClickOutside = () => {
      setCorCategoriaSelecionada(null);
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <h2>Gráfico de Itens por Categoria do Dia Atual</h2>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="categoria" />
            <YAxis tickFormatter={(value) => `R$ ${value}`} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {data.map((item) => (
              <Line key={item.categoria} type="monotone" dataKey="valor" stroke={item.cor} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="table-container">
        <table className="modern-table">
          <thead>
            <tr>
              <th>Categoria</th>
              <th className={corCategoriaSelecionada ? "valor-header categoria-selecionada" : "valor-header"}>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.categoria}>
               <td style={{ backgroundColor: item.cor, border: '1px solid black' }}>
  <span style={{ fontWeight: 'bold', color: 'white', padding: '8px' }}>{item.categoria}</span>
</td>

                <td style={{ color: item.cor }}>R$ {item.valor.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RelatorioDiaMobile;

