import React, { useEffect, useState } from 'react';
import { database } from './firebaseConfig';
import { ref, onValue, off, push, set } from 'firebase/database';
import './MobileCarousel.css';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const MobileCarousel = () => {
const navigate = useNavigate();
  const [carouselData, setCarouselData] = useState([]);
  const carouselRef = React.useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [showCarousel, setShowCarousel] = React.useState(false);
  const [selectedItemId, setSelectedItemId] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [itemName, setItemName] = React.useState('');
  const [itemValue, setItemValue] = React.useState('');
  const [itemDate, setItemDate] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isCarouselVisible, setCarouselVisible] = useState(false); // Nova variável de estado

  const handleNextSlide = () => {
    if (currentSlide === carouselData.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(carouselData.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleCategoryClick = () => {
    setShowCarousel(!showCarousel);
    setCarouselVisible(!showCarousel); // Atualiza a visibilidade do carrossel ao clicar na categoria
  };

  const handleItemClick = (itemId, categoryName, categoryType) => {
  setSelectedItemId(itemId);
  setSelectedCategory({ id: itemId, nome: categoryName, tipo: categoryType });
  setCarouselVisible(false); // Feche o carrossel quando um item é clicado
};


  const handleValueChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setItemValue(formattedValue);
  };

  const handleNameChange = (e) => {
    const uppercaseName = e.target.value.toUpperCase();
    setItemName(uppercaseName);
  };

  const formatCurrency = (value) => {
    const formattedValue = value
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/^0+/g, '') // Remove zeros à esquerda
      .padStart(3, '0') // Adiciona zeros à esquerda até ter pelo menos 3 dígitos
      .replace(/(\d{2})$/, ',$1') // Adiciona a vírgula para separar os centavos
      .replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2'); // Adiciona o ponto como separador de milhares

    return formattedValue;
  };

  const handleSave = () => {
    if (!selectedCategory || !selectedCategory.id) {
    setErrorMessage('Nenhuma categoria selecionada!');
    return;
  }

    if (itemName.trim() === '' || itemValue.trim() === '') {
      setErrorMessage('Preencha todos os campos!');
      return;
    }

    setErrorMessage('');

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = String(currentDate.getDate()).padStart(2, '0');

    const newItemRef = push(
      ref(database, `itens/${year}/${month}/${day}`)
    );
    const newItemKey = newItemRef.key;

    const newItemData = {
      id: newItemKey, // Adicione o ID do item
      nome: itemName,
      valor: itemValue,
      data: currentDate.toLocaleString(), // Data e hora atual
      categoria: {
        id: selectedCategory.id, // Adicione o ID da categoria
        nome: selectedCategory.nome,
        tipo: selectedCategory.tipo,
      },
    };

    set(ref(database, `itens/${year}/${month}/${day}/${newItemKey}`), newItemData)
      .then(() => {
        console.log('Item salvo com sucesso!');
        setItemName('');
        setItemValue('');
        setItemDate('');
        setSelectedCategory(null);
        setSuccessMessage('Nota adicionada com sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao salvar o item:', error);
      });
  };

  useEffect(() => {
    const databaseRef = ref(database, 'categorias');
    const callback = onValue(databaseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const carouselItems = Object.entries(data).map(([id, item]) => ({
          id,
          nome: item.nome,
          tipo: item.tipo,
        }));
        setCarouselData(carouselItems);
      }
    });

    return () => {
      off(databaseRef, callback);
    };
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft = carousel.offsetWidth * currentSlide;
    }
  }, [currentSlide]);

  useEffect(() => {
  if (successMessage) {
    const timeoutId = setTimeout(() => {
      setSuccessMessage('');
      navigate('/');
    }, 3000); // Redirecionar após 3 segundos (3000 milissegundos)

    return () => clearTimeout(timeoutId);
  }
}, [successMessage, navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setItemDate(new Date().toLocaleString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getItemClassName = (itemId) => {
    const selectedItem = carouselData.find((item) => item.id === itemId);

    if (itemId === selectedItemId && selectedItem && selectedItem.tipo === 'saida') {
      return 'carousel-item red';
    }

    if (itemId === selectedItemId) {
      return 'carousel-item selected';
    }

    return 'carousel-item';
  };

  const getCategoryButtonClassName = () => {
    if (selectedCategory) {
      return selectedCategory.tipo === 'saida' ? 'category-button red' : 'category-button blue';
    }
    return 'category-button';
  };

const handleGoHome = () => {
    // Implemente a lógica para voltar para a página inicial aqui
    // Por exemplo, você pode usar navigate('/') para redirecionar para a página inicial
    navigate('/'); // Redireciona para a página inicial
  };

  return (
    <div className="mobile-carousel-container">
      <div className="form">
        <h2>AdicionE As Notas</h2>
<div className="go-home-button">
         <div className="icon-x-container" onClick={handleGoHome}>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </div>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="form-field">
          <label htmlFor="name">Nome:</label>
          <input type="text" id="name" value={itemName} onChange={handleNameChange} />
        </div>
        <div className="form-field">
          <label htmlFor="value">Valor:</label>
          <input
            type="text"
            id="value"
            value={itemValue}
            onChange={handleValueChange}
            placeholder="0,00"
          />
        </div>
        <div className="form-field">
          <label htmlFor="date">Data:</label>
          <input type="text" id="date" value={itemDate} readOnly />
        </div>
        <button className="save-button" onClick={handleSave}>
          Salvar
        </button>
        <div className={getCategoryButtonClassName()} onClick={handleCategoryClick}>
          <label htmlFor="category">Categoria:</label>
          <input
            type="text"
            id="category"
            readOnly
            value={
              isCarouselVisible
                ? 'Carrossel sendo mostrado'
                : selectedCategory
                  ? `${selectedCategory.nome || 'Nome da Categoria'} - ${
                      selectedCategory.tipo || 'Tipo da Categoria'
                    }`
                  : 'Clique para mostrar o carrossel'
            }
          />
        </div>
        {successMessage && (
          <p className="success-message" style={{ backgroundColor: 'green' }}>
            {successMessage}
          </p>
        )}
      </div>
      {showCarousel && (
        <div className="carousel" ref={carouselRef}>
          {carouselData.map((item) => (
            <div
              className={`${getItemClassName(item.id)}`}
              key={item.id}
              onClick={() => handleItemClick(item.id, item.nome, item.tipo)}
            >
              <h3>{item.nome}</h3>
              <p>{item.tipo}</p>
            </div>
          ))}
        </div>
      )}
      <button className="carousel-button prev-button" onClick={handlePrevSlide}>
        &#8249;
      </button>
      <button className="carousel-button next-button" onClick={handleNextSlide}>
        &#8250;
      </button>
    </div>
  );
};

export default MobileCarousel;

