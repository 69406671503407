import React, { useState, useEffect, useRef } from 'react';
import { database } from './firebaseConfig';
import { ref, onValue, update,remove} from 'firebase/database';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Dialog,
} from '@mui/material';
import Notification from './Notification'; // Certifique-se de ajustar o caminho conforme necessário
import { parse, format } from 'date-fns';
import CustomPagination from './CustomPaginationMobile';
import MobileFilteredResults from './MobileFilteredResults';
import './MobileSavedItems.css'; // Importando o arquivo de estilo

const ITEMS_PER_PAGE = 5;

const MobileSavedItems = () => {
const [showMoreMessage, setShowMoreMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [data, setData] = useState([]);
  const [totalDay, setTotalDay] = useState('');
  const [todayData, setTodayData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editedItemData, setEditedItemData] = useState({
    nome: '',
    valor: '0.00',
    data: '',
    categoria: { id: '', nome: '', tipo: '' },
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(false);
const [itemsToShowMain, setItemsToShowMain] = useState([]);
const [filteredItems, setFilteredItems] = useState([]);

const [showNotification, setShowNotification] = useState(false);
const [notificationMessage, setNotificationMessage] = useState('');



const updateItemsToShowMain = (searchTerm) => {
  if (searchTerm.trim() === '') {
    setItemsToShowMain(todayData.slice(startIndex, endIndex));
  } else {
    const filteredItems = todayData.filter(item =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setItemsToShowMain(filteredItems);
  }
};



 // Função para ordenar os itens por hora, minuto e segundos
const sortItemsByTime = (items) => {
  return items.sort((a, b) => {
    const timeA = parse(a.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    const timeB = parse(b.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    return timeB - timeA; // Ordena do mais recente para o mais antigo
  });
};





  useEffect(() => {
    setShowMoreMessage(todayData.length > 6);
    const filteredTodayData = getTodayData();
    const sortedData = sortItemsByTime(filteredTodayData); // Ordena os itens
    setTodayData(sortedData);

    const totalValue = calculateTotalValue(sortedData);
    setTotalDay(totalValue);
  }, [data, selectedDate]);


useEffect(() => {
  updateItemsToShowMain(searchTerm);
}, [searchTerm, todayData]);



  const handleSearchChange = (event) => {
  const searchTerm = event.target.value;
  setSearchTerm(searchTerm);
  setSearchActive(searchTerm.trim() !== ''); // Ativa a busca se o termo de busca não estiver vazio

  if (searchTerm.trim() === '') {
    setFilteredItems([]);
  } else {
    const filteredItems = todayData.filter(item =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredItems);
  }

  setCurrentPage(1);

  if (event.target.value.trim() === '') {
    setSearchActive(false);
    setFilteredItems([]);
  }
};



  const handleValueChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^\d]/g, '');

    let formattedValue = '';

    if (sanitizedValue.length === 0) {
      formattedValue = '';
    } else {
      let integerPart = sanitizedValue.slice(0, -2);
      const decimalPart = sanitizedValue.slice(-2);

      if (integerPart.length === 0) {
        integerPart = '0';
      } else {
        integerPart = parseInt(integerPart).toLocaleString('pt-BR');
      }

      formattedValue = `${integerPart},${decimalPart}`;
    }

    setEditedItemData((prevData) => ({
      ...prevData,
      valor: formattedValue,
    }));
  };

  const handleCategorySelect = (selectedCategory) => {
  setEditingItem((prevItem) => ({
    ...prevItem,
    categoria: selectedCategory,
  }));
  setEditedItemData((prevData) => ({
    ...prevData,
    categoria: selectedCategory,
  }));
};



  const handleSaveEditedItem = () => {
    const updatedItem = {
      ...editingItem,
      nome: editedItemData.nome,
      valor: editedItemData.valor.replace(/[^\d.,]/g, ''),
      categoria: editedItemData.categoria,
    };

    const selectedCategory = categories.find((category) => category.id === editedItemData.categoria.id);

    if (selectedCategory) {
      updatedItem.categoria = {
        id: selectedCategory.id,
        nome: selectedCategory.nome,
        tipo: selectedCategory.tipo,
      };
    }

    const itemDate = parse(editingItem.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    const editedItemPath = `itens/${itemDate.getFullYear()}/${itemDate.toLocaleString('default', {
      month: 'long',
    })}/${String(itemDate.getDate()).padStart(2, '0')}/${editingItem.id}`;

    update(ref(database, editedItemPath), updatedItem)
      .then(() => {
        setEditModalOpen(false);
        setEditingItem(null);
      })
      .catch((error) => {
        console.error('Erro ao salvar as alterações:', error);
      });
  };

  const handleEditItem = (item) => {
    setEditingItem(item);

    setEditedItemData({
      ...item,
      valor: formatCurrency(item.valor),
    });

    setEditModalOpen(true);
  };


  const handleShowDeleteConfirmation = (item) => {
  setItemToDelete(() => item);
  setDeleteConfirmationOpen(true);
};

  const handleCancelDelete = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };


  const handleConfirmDelete = async () => {
  try {
    if (!itemToDelete) {
      throw new Error('Nenhum item selecionado para exclusão');
    }

    const itemToDeleteId = itemToDelete.id;
    const itemDate = parse(itemToDelete.data, 'dd/MM/yyyy, HH:mm:ss', new Date());
    const itemPath = `itens/${itemDate.getFullYear()}/${itemDate.toLocaleString('default', { month: 'long' })}/${String(
      itemDate.getDate()
    ).padStart(2, '0')}/${itemToDeleteId}`;

    await remove(ref(database, itemPath));

     setTodayData(todayData.filter((item) => item.id !== itemToDeleteId));

    setDeleteConfirmationOpen(false);
    setItemToDelete(null);

    // Definindo a notificação
    setShowNotification(true);
    setNotificationMessage('Item excluído com sucesso!');

    // Fechando o modal de confirmação
    handleCancelDelete();
  } catch (error) {
    console.error('Erro ao excluir o item:', error.message);

    // Definindo a notificação de erro
    setShowNotification(true);
    setNotificationMessage('Ocorreu um erro ao excluir o item.');
  }
};


  const handleDateChange = (e) => {
    const selectedDateString = e.target.value;
    const selectedYear = parseInt(selectedDateString.slice(0, 4));
    const selectedMonth = parseInt(selectedDateString.slice(5, 7)) - 1;
    const selectedDay = parseInt(selectedDateString.slice(8, 10));

    const newSelectedDate = new Date(selectedYear, selectedMonth, selectedDay);
    setSelectedDate(newSelectedDate);
  };

  const formatCurrency = (value) => {
    const numericValue = value.replace(/\D/g, '');
    const centsValue = parseFloat(numericValue) / 100;
    const formattedValue = centsValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedValue;
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataRef = ref(database, 'itens');
      const categoriesRef = ref(database, 'categorias');

      onValue(dataRef, (snapshot) => {
        const dataObj = snapshot.val();
        if (dataObj) {
          const dataArray = Object.values(dataObj);
          setData(dataArray);
        } else {
          setData([]);
        }
      });

      onValue(categoriesRef, (snapshot) => {
        const categoriesData = snapshot.val();
        if (categoriesData) {
          const categoriesArray = Object.entries(categoriesData).map(([id, category]) => ({
            id,
            ...category,
          }));
          setCategories(categoriesArray);
        }
      });
    };

    fetchData();
  }, []);

 const getTodayData = () => {
  if (!data) return [];

  const selectedYear = selectedDate.getFullYear();
  const selectedMonth = selectedDate.toLocaleString('default', { month: 'long' });
  const selectedDay = String(selectedDate.getDate()).padStart(2, '0');

  const itemsRef = ref(database, `itens/${selectedYear}/${selectedMonth}/${selectedDay}`);
  const todayData = [];

  onValue(itemsRef, (snapshot) => {
    const dataObj = snapshot.val();
    if (dataObj) {
      const dataArray = Object.values(dataObj);
      todayData.push(...dataArray);
    }
  });

  return sortItemsByTime(todayData); // Ordena os itens
};

  useEffect(() => {
    setShowMoreMessage(todayData.length > 6);
    const filteredTodayData = getTodayData();
    setTodayData(filteredTodayData);

    const totalValue = calculateTotalValue(filteredTodayData);
    setTotalDay(totalValue);
  }, [data, selectedDate]);

  const calculateTotalValue = (selectedDataItems) => {
    if (!selectedDataItems || selectedDataItems.length === 0) {
      return '0.00';
    }

    const total = selectedDataItems.reduce(
      (acc, item) => acc + parseFloat(item.valor.replace(/\./g, '').replace(',', '.')),
      0
    );

    return total.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  };

  

  const goToPage = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};

 const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
 const itemsToShow = searchActive ? filteredItems : todayData;
const totalItems = itemsToShow.length;
const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
const paginatedItems = itemsToShow.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);




  return (
        <Box>
     <Box className="dateSelectorContainer">
        <div className="dateSelector">
 <TextField
  id="date"
  label="Selecionar Data"
  type="date"
  value={selectedDate ? `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}` : ''}
  onChange={handleDateChange}
  InputLabelProps={{
    shrink: true,
  }}
  InputProps={{
    endAdornment: <></>, // Isso remove completamente o rótulo "Limpar"
  }}
/>
<Notification
  message={notificationMessage}
  open={showNotification}
  handleClose={() => setShowNotification(false)}
/>

        </div>
      </Box>
 <Typography variant="subtitle1" className="notasDoDia" gutterBottom>
    Notas do Dia
  </Typography>
      <Typography variant="body1">
        Data: {selectedDate.toLocaleDateString()} - Total: R$ {totalDay}
      </Typography>
     <TextField
  label="Buscar"
  value={searchTerm}
  onChange={handleSearchChange}
  variant="outlined"
  fullWidth
  margin="normal"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    ),
  }}
/>
{searchActive && (
  <Typography variant="body2" color="textSecondary" style={{ color: 'red', fontWeight: 'bold' }}>
    Você está vendo os resultados da pesquisa.
  </Typography>
)}
      {searchActive && (
        <MobileFilteredResults
          items={todayData}
          searchTerm={searchTerm}
          handleEditItem={handleEditItem}
          handleDeleteItem={handleShowDeleteConfirmation}
        />
      )}
      {!searchActive && todayData.length > 0 && (
        <div
          className="pagination"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <CustomPagination totalPages={totalPages} currentPage={currentPage} goToPage={goToPage} />
        </div>
      )}
     {!searchActive && todayData.length > 0 && (
  <div style={{ maxHeight: '485px', overflowY: 'auto', marginBottom: '8px', border: '1px solid #ccc' }}>
    <List>
      {paginatedItems.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem>
          <ListItemText
            primary={item.nome}
            secondary={`Valor: R$ ${item.valor} - Data: ${item.data} - Categoria: ${item.categoria.nome} (${item.categoria.tipo})`}
          />
          <IconButton onClick={() => handleEditItem(item)}>
            <EditIcon />
          </IconButton>
        <IconButton onClick={() => handleShowDeleteConfirmation(item)}>
  <DeleteIcon />
</IconButton>

        </ListItem>
        {index < paginatedItems.length - 1 && <Divider />}
      </React.Fragment>
    ))}
    </List>
  </div>
)}
      {todayData.length === 0 && !searchActive && (
        <Typography variant="h6" gutterBottom>
          Sem notas nesse dia!!!
        </Typography>
      )}

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)} fullWidth maxWidth="xs">
        <Box p={3}>
          <Typography variant="h6" gutterBottom>
            Editar Item
          </Typography>
          <TextField
            label="Nome"
            fullWidth
            value={editedItemData.nome}
            onChange={(e) =>
              setEditedItemData((prevData) => ({
                ...prevData,
                nome: e.target.value,
              }))
            }
            margin="normal"
          />
          <TextField
            label="Valor"
            fullWidth
            value={editedItemData.valor}
            onChange={handleValueChange}
            margin="normal"
          />
         <FormControl fullWidth margin="normal">
  <InputLabel>Categoria</InputLabel>
 <div>
      <em>Selecione uma nova categoria</em>
    </div>
  <Select
    value={editedItemData.categoria}
    onChange={(e) => handleCategorySelect(e.target.value)}
  >
   
    {categories.map((category) => (
      <MenuItem key={category.id} value={category}>
        {category.nome} ({category.tipo})
      </MenuItem>
    ))}
  </Select>
</FormControl>

<Typography variant="body1" gutterBottom>
  Nome: {editingItem?.categoria.nome} 
</Typography>
<Typography variant="body1" gutterBottom>
  Tipo: ({editingItem?.categoria.tipo})
</Typography>


          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveEditedItem}
          >
            Salvar
          </Button>
        </Box>
      </Dialog>

      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <Box p={3}>
          <Typography variant="h6" gutterBottom>
            Confirmar Exclusão
          </Typography>
          <Typography variant="body1" paragraph>
            Tem certeza de que deseja excluir este item?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmDelete}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelDelete}
            style={{ marginLeft: '8px' }}
          >
            Cancelar
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MobileSavedItems;
