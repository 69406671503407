import React, { useState } from 'react';
import { Grid, Paper, Typography, Box, Pagination, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function FilteredResults({ items, searchTerm, selectedDate, handleEditItem, handleDeleteItem }) {
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const filteredItems = items.filter((item) => {
  return (
    (item.nome.toLowerCase().startsWith(searchTerm.toLowerCase())) ||
 (item.valor.toLowerCase().startsWith(searchTerm.toLowerCase())) ||
    (item.categoria.nome.toLowerCase().startsWith(searchTerm.toLowerCase())) ||
    (item.categoria.tipo.toLowerCase().startsWith(searchTerm.toLowerCase()))
  );
});


  const totalItems = filteredItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = filteredItems.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {totalItems === 0 ? (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>Nenhum item encontrado</AlertTitle>
          Infelizmente, não foi possível encontrar nenhum item com os critérios de busca fornecidos.
        </Alert>
      ) : (
        <Grid container spacing={4}>
          {displayedItems.map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} xl={2}>
              <Paper sx={{ p: 2, bgcolor: '#f5f5f5', margin: '0 auto', minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="subtitle1">
                    <span style={{ backgroundColor: 'yellow' }}>Nome:</span> {item.nome}
                    <br />
                    <span style={{ backgroundColor: 'yellow' }}>Valor:</span> {item.valor}
                    <br />
                    <span style={{ backgroundColor: 'yellow' }}>Data:</span> {item.data}
                    <br />
                    <span style={{ backgroundColor: 'yellow' }}>Categoria:</span>{' '}
                    {item.categoria ? item.categoria.nome : 'N/A'} -{' '}
                    {item.categoria ? item.categoria.tipo : 'N/A'}
                  </Typography>
                </div>
                <div>
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton color="primary" onClick={() => handleEditItem(item.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={() => handleDeleteItem(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
      {totalPages > 1 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
        </Box>
      )}
    </>
  );
}

export default FilteredResults;

