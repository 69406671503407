import React, { useState } from 'react';
import { List, ListItem, ListItemText, Divider, Typography, IconButton, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomPagination from './CustomPaginationMobile';

function MobileFilteredResults({ items, searchTerm, handleEditItem, handleDeleteItem }) {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 3;

  const displayedItems = items
    .filter((item) => {
      return (
        item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.categoria.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.categoria.tipo.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  const totalPages = Math.ceil(displayedItems.length / itemsPerPage);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = displayedItems.slice(indexOfFirstItem, indexOfLastItem);

  if (currentItems.length === 0) {
    return (
      <Box style={{ textAlign: 'center' }}>
        <Typography variant="h6">Nenhum resultado encontrado</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <div className="pagination-result" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <CustomPagination totalPages={totalPages} currentPage={currentPage} goToPage={goToPage} />
      </div>

      <div style={{ maxHeight: '485px', overflowY: 'auto', marginBottom: '8px', border: '1px solid #ccc' }}>
        <List>
          {currentItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={item.nome}
                  secondary={`Valor: R$ ${item.valor} - Data: ${item.data} - Categoria: ${item.categoria.nome} (${item.categoria.tipo})`}
                />
                <Stack direction="row">
                  <IconButton onClick={() => handleEditItem(item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteItem(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </ListItem>
              {index < currentItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Box>
  );
}

export default MobileFilteredResults;

